<template>
  <v-container class="Product_bar">
    <v-row>
      <v-col cols="4">
        <h4 class="System_admin_text_Product ml-2">BLIND AD SUMMARY</h4>
      </v-col>
    </v-row>

    <v-row>
      <v-simple-table>
        <template>
          <div
            class="col-12"
            v-for="items in items"
            :key="items.id"
            style="cursor: pointer"
          >
            <v-row class="outlined">
              <v-col cols="3">
                <v-img
                  :src="items.blindad_photo_file"
                  contain
                  class="img-class"
                >
                </v-img>
              </v-col>
              <v-col cols="9">
                <div class="title font-weight-semibold">
                  {{ items.primary_headline }}
                </div>
                <p></p>
                <div class="medium-text">
                  {{ items.summary_text }}
                </div>
              </v-col>

              <v-row>
                <v-col cols="3">
                  <p></p>
                  <div>
                    <p class="small">
                      <b style="font-style: italic">Asking Price:</b>
                      <span style="font-weight: 500">
                        {{ usCurrency.format(items.asking_price) }}</span
                      >
                    </p>
                  </div>
                  <div style="margin-top: -20px">
                    <span class="small"
                      ><b style="font-style: italic">Cash Flow:</b>
                      <span style="font-weight: 500">
                        {{ usCurrency.format(items.cashflow) }}</span
                      ></span
                    >
                  </div>
                </v-col>
                <v-col></v-col>
                <v-col cols="2">
                  <p></p>
                  <div style="margin-top: 30px">
                    <span class="small"
                      ><b style="font-style: italic">Location:</b>
                      <span style="font-weight: 500">
                        {{ items.state }}</span
                      ></span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-row>
          </div>

          <td></td>
        </template>
      </v-simple-table>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CommonServices from "../service/CommonServices";
import XLSX from "xlsx";
import { mask } from "vue-the-mask";

function formatAsCurrency(value, dec) {
  dec = dec || 0;
  if (value === null) {
    return 0;
  }
  return "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

export default {
  components: {},
  directives: {
    mask,
  },
  computed: {
    askingPriceFormatted: {
      get: function () {
        return formatAsCurrency(this.worksheetData.asking_price, 0);
      },
      set: function (newValue) {
        this.worksheetData.asking_price = Number(
          newValue.replace(/[^0-9.]/g, "")
        );
      },
    },
  },

  async created() {
    //this.checkPermissions();
    this.logActivity("Opened Blind Ad Summary");

    this.getData();

    this.usCurrency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  },

  data() {
    return {
      componentURL: `BlindAd`,
      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      valid: false,
      dialog: false,
      dialogDelete: false,
      dialogEdit: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",
      currentDeal: localStorage.getItem("Deal"),
      currentUser:
        localStorage.getItem("first_name") +
        " " +
        localStorage.getItem("last_name"),

      currency: "$",

      file: "",
      fileURL: "/",
      imagePreview: "",

      usCurrency: null,

      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: " ",
        precision: 0,
        masked: false,
      },

      items: [],

      userActivityItem: {
        user_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DDThh:mm"),
        page: "BlindAdWorksheet",
        action: "",
        deal_id: "",
        json: "",
      },

      mask: {
        maxDollar: "############",
        currency: "$###,###,###.00",
        zipCode: "#####",
        ssn: "###-##-####",
        value: "######",
        phone: "(###) ###-####",
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {},

  updated() {
    window.print();
  },

  methods: {
    checkPermissions() {
      if (this.isAdmin == "false") this.$router.push("/");
    },

    async logActivity(activity, dealid, json) {
      this.userActivityItem.action = activity;
      this.userActivityItem.logdate = this.$moment(String(Date())).format(
        "YYYY-MM-DDThh:mm"
      );
      this.userActivityItem.deal_id = dealid;
      this.userActivityItem.json = json;
      await CommonServices.postData("UserActivityLog", this.userActivityItem);
    },

    openTasks() {
      this.$router.push(`/Tasks/${this.$route.params.id}`);
    },

    openOffers() {
      this.$router.push(`/DealOffers/${this.$route.params.id}`);
    },

    openUploads() {
      //First write any missing Deal Folders to the upload table
      CommonServices.putData("Functions", { id: 3 })
        .then(() => {
          setTimeout(() => {
            this.$router.push(`/Uploads/${this.$route.params.id}`);
          }, 500);
        })
        .catch((error) => {
          console.error("Put Function Error: ", error);
        });
    },

    downloadFile(url, filename) {
      CommonServices.downloadFile(url, filename);
    },

    displayFile(item) {
      this.worksheetData.file_name = item.title;
    },

    selectFile() {
      this.file = this.$refs.file.files[0];
      this.updatePreview();
    },

    openUpload() {
      document.getElementById("file-input").click();
    },

    updatePreview(e) {
      var reader,
        files = e.target.files;

      reader = new FileReader();

      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };

      reader.readAsDataURL(files[0]);

      this.file = this.$refs.file.files[0];
      this.worksheetData.file_name = this.file.name;
    },

    exportToExcel() {
      // On Click Excel download button
      // export json to Worksheet of Excel
      var reportWS = XLSX.utils.json_to_sheet(this.items);
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheets to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, reportWS, "User Folder Permissions"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "Folder Permissions.xlsx");
    },

    getData() {
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      CommonServices.listWithParams(this.componentURL, { params: params })
        .then((response) => {
          this.items = response.results;
          console.log("Items:", this.items);
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style src="../css/styles.css" scoped></style>
